import store from '../store'

// Admin pages
const FlashScreen = () => import("@/pages/FlashScreen.vue");
const Waiting = () => import("@/pages/Waiting.vue");
const NotificationCallBack = () => import("@/pages/NotificationCallBack.vue");
const Login = () => import("@/pages/Login.vue");
const LoginWithOtp = () => import('@/pages/WithoutLogin/LoginWithOtp.vue');
const ForgetUsernameAndPassword = () => import('@/pages/WithoutLogin/ForgetUsernameAndPassword.vue');
const ForgetUsername = () => import('@/pages/WithoutLogin/forgetUsername.vue');
const forgetPassword = () => import('@/pages/WithoutLogin/forgetPassword.vue');
const OneTimePassword = () => import('@/pages/WithoutLogin/OneTimePassword.vue');
const UsernameForgetList = () => import('@/pages/WithoutLogin/UsernameForgetList.vue');
const LoginUserList = () => import('@/pages/WithoutLogin/LoginUserList.vue');
const ResetPassword = () => import('@/pages/WithoutLogin/ResetPassword.vue');
const AccountChange = () => import('@/pages/WithoutLogin/AccountChange.vue');

const Dashboard = () => import("@/pages/Dashboard.vue");
const NotificationList = () => import("@/pages/NotificationList.vue");
const MasterLayount = () => import("@/layout/MasterLayount.vue");
const Header = () => import("@/layout/Header.vue");
const LeftSideBar = () => import("@/layout/LeftSideBar.vue");
const RightSideBar = () => import("@/layout/RightSideBar.vue");
const InnerHeader = () => import("@/layout/InnerHeader.vue");
const MobileHeader = () => import("@/layout/MobileHeader.vue");
const RouterLayount = () => import("@/layout/RouterLayount.vue");

/* Homework */
const Homework = () => import("@/pages/Homework/index.vue");
const HomeworkUpload = () => import("@/pages/Homework/Upload.vue");

/* E-learning */
const eLearning = () => import("@/pages/ELearning/index.vue");
const eLearningView = () => import("@/pages/ELearning/view.vue");

/* Notice */
const Notice = () => import("@/pages/Notice/index.vue");
const NoticeView = () => import("@/pages/Notice/view.vue");

/* Attendance */
const Attendance = () => import("@/pages/Attendance/Index.vue");

/* Live Class */
const LiveClass = () => import("@/pages/LiveClass/index.vue");

/* Event */
const Event = () => import("@/pages/Event/Index.vue");
const EventView = () => import("@/pages/Event/View.vue");

/* Event */
const Gallery = () => import("@/pages/Gallery/Index.vue");
const GalleryView = () => import("@/pages/Gallery/View.vue");

/* Holiday */
const Holiday = () => import("@/pages/Holiday/Index.vue");
const HolidayView = () => import("@/pages/Holiday/View.vue");

/* Class Work */
const ClassWork = () => import("@/pages/ClassWork/Index.vue");

/* Academic Calendar */
const AcademicCalendar = () => import("@/pages/AcademicCalendar/Index.vue");

/* Counselling */
const Counselling = () => import("@/pages/Counselling/Index.vue");

/* Food Menu */
const FoodMenu = () => import("@/pages/FoodMenu/Index.vue");

/* Study Material */
const StudyMaterial = () => import("@/pages/StudyMaterial/Index.vue");

/* Exam */
const ExamIndex = () => import("@/pages/Exam/index.vue");
const Exam = () => import("@/pages/Exam/exam.vue");
const ExamResult = () => import("@/pages/Exam/result.vue");
const ExamPaper = () => import("@/pages/Exam/ExamPaper.vue");

/* Result */
const Result = () => import("@/pages/Result/Index.vue");

/* Transportation */
const Transportation = () => import("@/pages/Transportation/Index.vue");

/* Leave */
const Leave = () => import("@/pages/Leave/Index.vue");

/* Testimonial */
const Testimonial = () => import("@/pages/Testimonial/Index.vue");

/* Feedback */
const Feedback = () => import("@/pages/Feedback/Index.vue");
const FeedbackAdd = () => import("@/pages/Feedback/Add.vue");

/* Feedback */
const Vaccination = () => import("@/pages/Vaccination/index.vue");


/* Profile */
const ViewProfile = () => import("@/pages/Profile/Index.vue");
const PersonalInformation = () => import("@/pages/Profile/PersonalInformation.vue");
const AddAccount = () => import("@/pages/Profile/AddAccount.vue");
const Academicyear = () => import("@/pages/Profile/Academicyear.vue");
const PrivacyPolicy = () => import("@/pages/Profile/PrivacyPolicy.vue");
const RefundPolicy = () => import("@/pages/Profile/RefundPolicy.vue");
const TermsCondition = () => import("@/pages/Profile/TermsCondition.vue");
const HelpAndContact = () => import("@/pages/Profile/HelpAndContact.vue");
const ParentCard = () => import("@/pages/Profile/ParentCard.vue");
const IdCard = () => import("@/pages/Profile/IdCard.vue");
const Logout = () => import("@/pages/Profile/Logout.vue");

/* Fees */
const Fees = () => import("@/pages/Fees/index.vue");
const FeesStatus = () => import("@/pages/Fees/status.vue");


/* TimeTable */
const TimeTable = () => import("@/pages/TimeTable/Index.vue");

/* TimeTable */
const Appreciation = () => import("@/pages/Appreciation/index.vue");
/* Achievement */
const Achievement = () => import("@/pages/Achievement/Index.vue");
const AchievementView = () => import("@/pages/Achievement/View.vue");

/* Birthday */
const Birthday = () => import("@/pages/Birthday/Index.vue");


const routes = [
    {
        path: "/",
        components: {
            default: Login,
            RouterLayount: RouterLayount,
        },
        children: [
            {
                path: "/",
                name: "Login",
                component: Login,
                meta: {
                    title: 'Login'
                }
            },
            {
                path: "/flash-screen",
                name: 'FlashScreen',
                component: FlashScreen,
                meta: {
                    title: 'FlashScreen',
                    auth: true
                }
            },
            {
                path: "/notification-call-back",
                name: 'NotificationCallBack',
                component: NotificationCallBack,
                meta: {
                    title: 'Notification',
                    auth: true
                }
            },
            {
                path: "/login-with-otp",
                name: 'LoginWithOtp',
                component: LoginWithOtp,
                meta: {
                    title: 'Login With Otp',
                    auth: false
                }
            },
            {
                path: "/login-user-list",
                name: 'LoginUserList',
                component: LoginUserList,
                meta: {
                    title: 'Login User List',
                    auth: false
                }
            },
            {
                path: "/forget-username-and-password",
                name: 'ForgetUsernameAndPassword',
                component: ForgetUsernameAndPassword,
                meta: {
                    title: 'Forget Username And Password',
                    auth: false
                }
            },
            {
                path: "/forget-username",
                name: 'ForgetUsername',
                component: ForgetUsername,
                meta: {
                    title: 'Forget Username',
                    auth: false
                }
            },
            {
                path: "/forget-password",
                name: 'forgetPassword',
                component: forgetPassword,
                meta: {
                    title: 'Forget Password',
                    auth: false
                }
            },
            {
                path: "/one-time-password",
                name: 'OneTimePassword',
                component: OneTimePassword,
                meta: {
                    title: 'One Time Password',
                    auth: false
                }
            },
            {
                path: "/username-forget-list",
                name: 'UsernameForgetList',
                component: UsernameForgetList,
                meta: {
                    title: 'Username Forget List',
                    auth: false
                }
            },
            {
                path: "/reset-password",
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    title: 'Reset Password',
                    auth: false
                }
            },
            {
                path: "/account-change/:key",
                name: 'AccountChange',
                component: AccountChange,
                meta: {
                    title: 'Account Change',
                    auth: true
                }
            },
            {
                path: "/waiting",
                name: 'Waiting',
                component: Waiting,
                meta: {
                    title: 'Waiting',
                    auth: false
                }
            },
        ]
    },
    {
        path: "/",
        components: {
            MasterLayount: MasterLayount,
        },
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: Dashboard,
                alias: ["/explore-menu"],
                meta: {
                    title: "Dashboard"
                }
            },
            {
                path: "notifications",
                name: "NotificationList",
                component: NotificationList,
                alias: ["/explore-menu"],
                meta: {
                    title: "Notification List"
                }
            },
            {
                path: "homework",
                name: "Homework",
                component: Homework,
                meta: {
                    title: "Homework",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "result",
                name: "Result",
                component: Result,
                meta: {
                    title: "Result",
                    search: false,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "homework/show/:token",
                name: "HomeworkUpload",
                component: HomeworkUpload,
                meta: {
                    title: "Homework Upload",
                    search: true,
                    notification: true,
                    backPath: 'Homework'
                },
            },
            {
                path: "e-learning",
                name: "eLearning",
                component: eLearning,
                meta: {
                    title: "E-Learning",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "e-learning/:slug",
                name: "eLearningView",
                component: eLearningView,
                meta: {
                    title: "E-Learning View",
                    search: true,
                    notification: true,
                    backPath: 'eLearning'
                }
            },
            {
                path: "attendance",
                name: "Attendance",
                component: Attendance,
                meta: {
                    title: "Attendance",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "live-class",
                name: "LiveClass",
                component: LiveClass,
                meta: {
                    title: "Live Class",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "event",
                name: "Event",
                component: Event,
                meta: {
                    title: "Event",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "event/view/:token",
                name: "EventView",
                component: EventView,
                meta: {
                    title: "Event View",
                    search: false,
                    notification: false,
                    backPath: 'Event'
                },
            },
            {
                path: "holiday",
                name: "Holiday",
                component: Holiday,
                meta: {
                    title: "Holiday",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "holiday/view/:token",
                name: "HolidayView",
                component: HolidayView,
                meta: {
                    title: "Holiday View",
                    search: false,
                    notification: false,
                    backPath: 'Holiday'
                },
            },
            {
                path: "class-work",
                name: "ClassWork",
                component: ClassWork,
                meta: {
                    title: "Class Work",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "academic-calendar",
                name: "AcademicCalendar",
                component: AcademicCalendar,
                meta: {
                    title: "Academic Calendar",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "counselling",
                name: "Counselling",
                component: Counselling,
                meta: {
                    title: "Counselling",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "food-menu",
                name: "FoodMenu",
                component: FoodMenu,
                meta: {
                    title: "Food Menu",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "study-material",
                name: "StudyMaterial",
                component: StudyMaterial,
                meta: {
                    title: "Study Material",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "/exam",
                component: ExamIndex,
                children: [
                    {
                        path: "",
                        name: "Exam",
                        component: Exam,
                        meta: {
                            title: "Exam",
                            search: false,
                            notification: false,
                            backPath: 'Dashboard'
                        },
                    },
                    {
                        path: "result",
                        name: "ExamResult",
                        component: ExamResult,
                        meta: {
                            title: "Exam",
                            search: false,
                            notification: false,
                            backPath: 'Dashboard'
                        },
                    },
                ],
            },
            {
                path: "exam/paper/:slug",
                name: "ExamPaper",
                component: ExamPaper,
                meta: {
                    title: "Exam Paper",
                    search: false,
                    notification: false,
                    backPath: 'Exam'
                },
            },
            {
                path: "gallery",
                name: "Gallery",
                component: Gallery,
                meta: {
                    title: "Gallery",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "gallery/view/:token",
                name: "GalleryView",
                component: GalleryView,
                meta: {
                    title: "Images / Videos",
                    search: false,
                    notification: false,
                    backPath: 'Gallery'
                },
            },
            {
                path: "transportation",
                name: "Transportation",
                component: Transportation,
                meta: {
                    title: "Transportation",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "feedback",
                name: "Feedback",
                component: Feedback,
                meta: {
                    title: "Feedback",
                    search: false,
                    notification: false,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "feedback/add",
                name: "FeedbackAdd",
                component: FeedbackAdd,
                meta: {
                    title: "Add Feedback",
                    search: false,
                    notification: false,
                    backPath: 'Feedback'
                },
            },
            {
                path: "notice",
                name: "Notice",
                component: Notice,
                meta: {
                    title: "Notice",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "notice/:token",
                name: "NoticeView",
                component: NoticeView,
                meta: {
                    title: "Notice View",
                    search: true,
                    notification: true,
                    backPath: 'Notice'
                },
            },
            /* Profile Menus Start */
            {
                path: "profile",
                name: "ViewProfile",
                component: ViewProfile,
                meta: {
                    title: "Profile",
                    backPath: 'Dashboard',
                    inner_header: false
                }
            },
            {
                path: "profile/personal-information",
                name: "PersonalInformation",
                component: PersonalInformation,
                meta: {
                    title: "Personal Information",
                    backPath: 'ViewProfile'
                }
            },
            {
                path: "parent-card",
                name: "ParentCard",
                component: ParentCard,
                meta: {
                    title: "Parent Card",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "id-card",
                name: "IdCard",
                component: IdCard,
                meta: {
                    title: "ID Card",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "switch-user",
                name: "AddAccount",
                component: AddAccount,
                meta: {
                    title: "Switch User",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "academic-year",
                name: "Academicyear",
                component: Academicyear,
                meta: {
                    title: "Academic Year",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {
                    title: "Privacy Policy",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "refund-policy",
                name: "RefundPolicy",
                component: RefundPolicy,
                meta: {
                    title: "Refund Policy",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "terms-condition",
                name: "TermsCondition",
                component: TermsCondition,
                meta: {
                    title: "Terms Condition",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile'
                },
            },
            {
                path: "help-and-contact",
                name: "HelpAndContact",
                component: HelpAndContact,
                meta: {
                    title: "Help & Contact",
                    search: false,
                    notification: false,
                    backPath: 'ViewProfile',
                },
            },
            {
                path: "logout",
                name: "Logout",
                component: Logout,
                meta: {
                    title: "Log out",
                    search: true,
                    notification: true,
                    backPath: 'ViewProfile',
                },
            },
            /* Profile Menus End */
            {
                path: "leave",
                name: "Leave",
                component: Leave,
                meta: {
                    title: "Leave",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "testimonial",
                name: "Testimonial",
                component: Testimonial,
                meta: {
                    title: "Testimonial",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "fees",
                name: "Fees",
                component: Fees,
                meta: {
                    title: "Fees",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "fees/status/:orderid",
                name: "FeesStatus",
                component: FeesStatus,
                meta: {
                    title: "Fees Status",
                    notification: true,
                    backPath: 'Fees'
                },
            },
            {
                path: "vaccination",
                name: "Vaccination",
                component: Vaccination,
                meta: {
                    title: "Vaccination",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "time-table",
                name: "TimeTable",
                component: TimeTable,
                meta: {
                    title: "Time Table",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "appreciation",
                name: "Appreciation",
                component: Appreciation,
                meta: {
                    title: "Appreciation",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "achievement",
                name: "Achievement",
                component: Achievement,
                meta: {
                    title: "Achievement",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                },
            },
            {
                path: "achievement/view/:token",
                name: "AchievementView",
                component: AchievementView,
                meta: {
                    title: "Achievement View",
                    search: true,
                    notification: true,
                    backPath: 'Achievement'
                }
            },
            {
                path: "birthday",
                name: "Birthday",
                component: Birthday,
                meta: {
                    title: "Birthday",
                    search: true,
                    notification: true,
                    backPath: 'Dashboard'
                }
            }
        ]
    }
];

export default routes;
