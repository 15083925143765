export default {
    state: {
        profiles: [],
        profileSkeleton: true,
        addresses: [],
        addressSkeleton: true,
        contactpage: null,
        contactpageSkeleton: false,
    },
    getters: {

    },
    actions: {
        async getProfile(context) {
            if (!context.state.profiles.length) {
                context.commit("ProfileSkeleton", true)
                await axios.get('student/profile')
                    .then((response) => {
                        context.commit("ProfileSkeleton", false)
                        context.commit("Profile", response.data)
                    })
            }

        },
        async getAddress(context) {
            if (!context.state.addresses.length) {
                context.commit("AddressSkeleton", true)
                await axios.get('student/address')
                    .then((response) => {
                        context.commit("AddressSkeleton", false)
                        context.commit("Address", response.data)
                    })
            }

        },
        async getContactPage(context) {
            if (!context.state.contactpage) {
                context.commit("ContactPageSkeleton", true)
                await axios.get('address')
                    .then((response) => {
                        context.commit("ContactPageSkeleton", false)
                        context.commit("ContactPage", response.data)
                    })
            }

        },
    },
    mutations: {
        Profile(state, data) {
            state.profiles = data
        },
        ProfileSkeleton(state, data) {
            state.profileSkeleton = data
        },
        Address(state, data) {
            state.addresses = data
        },
        AddressSkeleton(state, data) {
            state.addressSkeleton = data
        },
        ContactPage(state, data) {
            state.contactpage = data
        },
        ContactPageSkeleton(state, data) {
            state.contactpageSkeleton = data
        },
    },
    namespaced: true
}