import moment from "moment";
export default {
    state: {
        fees: [],
        fees_group_skeleton: true,
        receipts: [],
        receipt_skeleton: true,
        payment: null,
        payment_skeleton: true,
        fees_reminder: null,
        fees_reminder_expiry: null,
        bankDetails: [],
        online_pay: false,
    },
    getters: {
        feesGroups: (state) => {
            let durations = _.groupBy(state.fees.durations, 'group_id');
            return _.map(durations, function (data) {
                return _.groupBy(data, 'month')
            })
        }
    },
    actions: {
        async getFees(context, load = false) {
            if (!context.state.fees.length || load) {
                context.commit("FeesGroupSkeleton", true)
                await axios.get('student/fees')
                    .then((response) => {
                        context.commit("FeesGroupSkeleton", false)
                        if (response.data.fees === undefined) {
                            context.commit("Fees", response.data)
                        } else {
                            context.commit("Fees", response.data.fees)
                            context.commit("BankDetails", response.data.bank_details);
                            context.state.online_pay = response.data.online_pay
                        }
                    })
            }

        },
        async getPaymentData(context, orderNo) {
            context.commit("PaymentSkeleton", true)
            await axios.post('student/fees/order-data', { order_no: orderNo })
                .then((response) => {
                    context.commit("PaymentSkeleton", false)
                    context.commit("Payment", response.data)
                })

        },
        async getFeesReceipt(context) {
            if (!context.state.receipts.length) {
                await axios.get('student/fees/receipts')
                    .then((response) => {
                        context.commit("ReceiptSkeleton", false)
                        context.commit("Receipt", response.data)
                    })
            }

        },
        async getFeesReminder(context) {

            let store_expiry = localStorage.fees_reminder_expiry;
            let callRun = true;
            if (store_expiry) {
                let diff = moment().diff(store_expiry, 'minutes');
                if (0 <= diff) {
                    callRun = true
                } else {
                    context.commit("FeesReminder", null)
                    callRun = false
                }
            }

            if (!context.state.fees_reminder && callRun) {
                return axios.get('student/fees/reminder')
                    .then((response) => {
                        if (response.data.status) {
                            context.commit("FeesReminder", response.data)
                        }
                        context.commit("FeesReminderExpiry", response.data.after_expiry)
                    })
            }

        },
    },
    mutations: {
        Fees(state, data) {
            state.fees = data
        },
        FeesGroupSkeleton(state, data) {
            state.fees_group_skeleton = data
        },
        Receipt(state, data) {
            state.receipts = data
        },
        ReceiptSkeleton(state, data) {
            state.receipt_skeleton = data
        },
        Payment(state, data) {
            state.payment = data
        },
        PaymentSkeleton(state, data) {
            state.payment_skeleton = data
        },
        FeesReminder(state, data) {
            state.fees_reminder = data
        },
        FeesReminderExpiry(state, data) {
            let expiry = moment().add(data, 'm');
            localStorage.fees_reminder_expiry = expiry
            state.fees_reminder_expiry = expiry
        },
        BankDetails(state, data) {
            state.bankDetails = data
        },
    },
    namespaced: true
}