export default {
    state: {
        todayExams: [],
        todayExamsSkeleton: true,
        exam: null,
        examSkeleton: true,
        nextPage: null,
        nextPageLoad: false,
        filters: [],

        upComingExam: [],
        upComingexamSkeleton: true,
        upComingnextPage: null,
        upComingnextPageLoad: false,
        upComingfilters: [],

    },
    getters: {
    },
    actions: {
        async getTodayExams(context) {
            if (!context.state.todayExams.length) {
                await axios.get('student/exam')
                    .then((response) => {
                        context.commit("TodayExamSkeleton", false)
                        context.commit("TodayExam", response.data.data)
                        context.commit("Filter", response.data.filters)
                        context.commit("NextPage", response.data.links.next)

                    })
            }

        },
        async getExam(context, token) {
            var exam = context.state.todayExams.find(function (value) {
                if (value.token == token) {
                    return value;
                }
            });
            if (!exam) {

                await axios.get('student/exam/paper/' + token)
                    .then((response) => {
                        context.commit("ExamSkeleton", false);
                        context.commit("Exam", response.data);
                    })
            } else {
                context.commit("ExamSkeleton", false);
                context.commit("Exam", exam);
            }
        },

        getFilterData(context, filters) {
            context.commit("TodayExam", [])
            context.commit("TodayExamSkeleton", true)
            axios.get('student/exam?' + filters)
                .then((response) => {
                    context.commit("TodayExamSkeleton", false)
                    context.commit("TodayExam", response.data.data)
                    context.commit("NextPage", response.data.links.next)
                })

        },

        async getUpcomingExams(context) {
            if (!context.state.upComingExam.length) {
                await axios.get('student/exam/upcoming')
                    .then((response) => {
                        context.commit("UpcomingExamSkeleton", false)
                        context.commit("UpcomingExan", response.data.data)
                        context.commit("upFilter", response.data.filters)
                        context.commit("upNextPage", response.data.links.next)

                    })
            }

        },

        getUpExamFilterData(context, filters) {
            context.commit("UpcomingExan", [])
            context.commit("UpcomingExamSkeleton", true)
            axios.get('student/exam/upcoming?' + filters)
                .then((response) => {
                    context.commit("UpcomingExamSkeleton", false)
                    context.commit("UpcomingExan", response.data.data)
                    context.commit("upNextPage", response.data.links.next)
                })

        },
    },
    mutations: {
        TodayExam(state, data) {
            state.todayExams = data
        },
        TodayExamSkeleton(state, data) {
            state.todayExamsSkeleton = data
        },
        Exam(state, data) {
            state.exam = data
        },
        ExamSkeleton(state, data) {
            state.examSkeleton = data
        },
        Filter(state, data) {
            state.filters = data
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },

        UpcomingExan(state, data) {
            state.upComingExam = data
        },
        UpcomingExamSkeleton(state, data) {
            state.upComingexamSkeleton = data
        },
        upFilter(state, data) {
            state.upComingfilters = data
        },
        upNextPage(state, data) {
            state.upComingnextPage = data
        },
        upNextPageLoad(state, data) {
            state.upComingnextPageLoad = data
        },
    },
    namespaced: true
}